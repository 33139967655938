/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

.site-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.main-content {
  flex: 1;
}

* {
  margin: 0; /* Reset margin */
  padding: 0; /* Reset padding */
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

html, body {
  height: 100%;
  margin: 0; /* Reset margin for full height */
  padding: 0; /* Reset padding for full height */
}

